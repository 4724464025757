import '../scss/styles.scss'

import * as coreui from '@coreui/coreui'
import * as coreui_chartjs from '@coreui/chartjs'
import * as coreui_utils from '@coreui/utils'
import * as coreui_icons from '@coreui/icons'
import * as jquery from 'jquery'
import 'jquery-confirm';
import * as simplebar from 'simplebar'
import 'select2';


window.coreui = coreui
window.coreui.ChartJS = coreui_chartjs
window.coreui.Utils = coreui_utils
window.coreui.icons = coreui_icons
window.simplebar = simplebar
window.$ = jquery
window.jQuery = jquery
// require.context('@coreui/icons/sprites', true, /\.(svg)$/);
